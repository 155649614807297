<template>
  <el-card class="box-card serviceChargeManaged full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-row class="table-btns">
        <el-button type="text" icon="el-icon-edit" @click="editRole()" size="mini" class="text-btn">{{$t('addServiceChargeRules')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="serviceChargeList" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="name" :label="$t('name')" width="180"></el-table-column>
        <el-table-column prop="businessName" :label="$t('businessName')" width="180"></el-table-column>
        <el-table-column prop="businessType" :label="$t('dealType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.businessType==1">{{$t('internalDeal')}}</span>
            <span v-if="scope.row.businessType==2">{{$t('externalDeal')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="settlementRules" :label="$t('settlementRules')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.settlementRules==1">{{$t('proportionSettlement')}}(%)</span>
            <span v-if="scope.row.settlementRules==2">{{$t('fixedPriceSettlement')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="minValue" :label="$t('amountInvolvedRange')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span>{{scope.row.minValue}}
            <span style="margin:0 5px">~</span>
            <span class="franc">₣</span>{{scope.row.maxValue}}
          </template>
        </el-table-column>
        <el-table-column prop="settlementAmount" :label="$t('settlementAmount')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.settlementRules==1">{{scope.row.settlementAmount}}%</span>
            <span v-if="scope.row.settlementRules==2"><span class="franc">₣</span> {{scope.row.settlementAmount}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="remark" :label="$t('remark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column :label="$t('extend')" fixed="right" width=" 80">
          <template slot-scope="scope">
            <el-button size="mini" @click="editRole(scope.row)" type="primary">{{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer :title="$t('editServiceChargeRules')" :visible.sync="editRoleDrawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="500">
      <el-form :model="serviceChargeForm" :rules="rules" ref="serviceChargeForm" label-position="top">
        <el-form-item :label="$t('name')" prop="name">
          <el-input size="medium" v-model="serviceChargeForm.name" />
        </el-form-item>
        <el-form-item :label="$t('name')" prop="serviceChargeCode">
          <el-select size="medium" v-model="serviceChargeForm.serviceChargeCode" filterable style="width:100%">
            <el-option-group v-for="group in businessList2" :key="group.name" :label="$t(group.name)">
              <el-option v-for="item in group.child" :key="item.id" :label="$t(item.name)" :value="item.businessCode">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dealType')" prop="businessType">
          <el-select size="medium" v-model="serviceChargeForm.businessType" style="width:100%">
            <el-option :label="$t('internalDeal')" :value="1"></el-option>
            <el-option :label="$t('externalDeal')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 业务金额区间(该业务价值多少钱，如:0~100、101-200) -->
        <el-row>
          <el-col :span="11">
            <el-form-item :label="$t('amountInvolvedRange')" prop="minValue">
              <el-input size="medium" v-model="serviceChargeForm.minValue">
                <template slot="prepend"><i class="iconfont iconruishifalang"></i></template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" style="text-align:center;padding-top:50px;">~</el-col>
          <el-col :span="11">
            <el-form-item label=" " prop="maxValue">
              <el-input size="medium" v-model="serviceChargeForm.maxValue">
                <template slot="prepend"><i class="iconfont iconruishifalang"></i></template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('settlementRules')" prop="settlementRules">
          <el-select size="medium" v-model="serviceChargeForm.settlementRules" style="width:100%">
            <el-option :label="$t('proportion')+'(%)'" :value="1"></el-option>
            <el-option :label="$t('fixedPrice')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 百分比 -->
        <el-form-item v-if="serviceChargeForm.settlementRules==1" :label="$t('proportionSettlementAmount')" prop="settlementAmount">
          <el-input size="medium" v-model="serviceChargeForm.settlementAmount">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <!-- 固定金额 -->
        <el-form-item v-if="serviceChargeForm.settlementRules==2" :label="$t('settlementAmount')" prop="settlementAmount">
          <el-input size="medium" v-model="serviceChargeForm.settlementAmount">
            <template slot="prepend"><i class="iconfont iconruishifalang"></i></template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('remark')">
          <el-input size="medium" v-model="serviceChargeForm.remark" />
        </el-form-item>
        <el-form-item class="bottom-button">
          <el-button v-if="serviceChargeForm.id" size="medium" type="primary" @click="updateSubmit('serviceChargeForm')" :loading="isloading">{{$t('submit')}}</el-button>
          <el-button v-else size="medium" type="primary" @click="addSubmit('serviceChargeForm')" :loading="isloading">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import business from "@/api/business";
import verification from "@/assets/uilt/verification"
export default {
  name: "serviceChargeManaged",
  data () {
    return {
      queryForm: {},
      serviceChargeForm: {},
      //table高度，动态设置
      queryTableHeight: "",
      //table显示内容
      serviceChargeList: [],
      businessList: [],
      //显示加载中
      isloading: false,
      //新增\编辑角色
      editRoleDrawer: false,
      //设置角色权限
      editJurisdictionDrawer: false,
    };
  },
  computed: {
    /**过滤父业务元素 */
    businessList2 () {
      if (!this.businessList) return [];
      let _this = this;
      let parents = this.businessList.filter(function (t) { return t.parentId == 0 });
      parents.forEach(function (t) {
        t.child = _this.businessList.filter(function (t2) { return t.id == t2.parentId; })
      })
      return parents;
    },
    rules () {
      let verify = new verification(this)
      return {
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' },],
        maxValue: [{ validator: verify.number, required: true, trigger: 'blur' },],
        minValue: [{ validator: verify.number, required: true, trigger: 'blur' },],
        settlementRules: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        businessType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        settlementAmount: [{ validator: verify.number, required: true, trigger: 'blur' }],
      }
    }
  },
  watch: {
    serviceChargeList () {
      if (this.businessList.length !== 0)
        this.$nextTick(function () { this.getBusinessName() })
    },
    businessList () {
      if (this.serviceChargeList.length !== 0)
        this.$nextTick(function () { this.getBusinessName() })
    }
  },
  methods: {
    getServiceChargeList () {
      let _this = this;
      business.getServiceChargeList({
        success: res => {
          if (res.code != 200) return;
          _this.serviceChargeList = res.data;
        }
      })
    },
    getBusinessList () {
      let _this = this;
      business.getList({
        success: res => {
          _this.businessList = res.data;
        }
      })
    },
    /**通过业务编号获取业务名称 */
    getBusinessName () {
      let _this = this;
      this.businessList.forEach(function (b) {
        if (b.parentId === 0) return true;
        let re = _this.serviceChargeList.filter(function (r) { return r.serviceChargeCode === b.businessCode });
        let parent = _this.businessList.find(function (b2) { return b2.id === b.parentId });

        for (let i = 0; i < re.length; i++)
          _this.$set(re[i], 'businessName', _this.$t(parent.name) + '/' + _this.$t(b.name));
      })
    },
    //其它操作
    editRole (data) {
      let _this = this;
      _this.serviceChargeForm = {}
      if (data) _this.serviceChargeForm = data;
      _this.editRoleDrawer = true;
    },

    addSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          _this.serviceChargeForm.parentId = _this.serviceChargeForm.parentId || 0;

          business.addServiceCharge({
            param: _this.serviceChargeForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getServiceChargeList();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
    updateSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          business.updateServiceCharge({
            param: _this.serviceChargeForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
  },
  created () { },
  mounted () {
    let _this = this;
    this.$nextTick(() => {
      let queryTableHeight = _this.$refs.pageHead.offsetHeight + 15;
      _this.queryTableHeight = queryTableHeight + 'px';
      _this.getServiceChargeList();
      _this.getBusinessList();
    })
  },
}
</script>
<style lang="less" scoped>
.tree-card {
  height: 300px;
}
</style>